const getDefaultState = () => ({
  student: {
    firstName: '',
    lastName: '',
    otherNames: '',
    birthDate: '',
    birthPlace: '',
    idNumber: '',
    gender: '',
    nationality: '',
    email: '',
    phoneNumber: '',
    street: '',
    postalCode: '',
    city: '',
    country: '',
    graduationDate: '',
    equivalenceDate: null,
    academicBackground1: '',
    academicBackground2: '',
    academicBackground3: '',
    file: null,
    // new props for para
    selectedCursusId: null,
    selectedLocations: [],

    hasStudiedInBelgium: true,
    willGraduateThisYear: true,
    establishmentName: '',
    establishmentCountry: 'BE',
    hasEquivalence: null,

    activities: ['', '', '', '', ''],

    hasRegForOtherCursus: false,
    otherCursusStudRegistered: '',
    hasRegInOtherSchool: false,
    otherSchoolsStudRegistered: '',
  },
});

const studentState = getDefaultState();

const mutations = {
  UPDATE_ACTIVITIES(state, data) {
    state.student.activities.splice(data.index, 1, data.value);
  },

  SET_STUDENT(state, data) {
    state.student[data.key] = data.value;
  },

  UPDATE_LOCATIONS(state, data) {
    state.student.selectedLocations.splice(data.start, data.end, data.value);
  },

  RESET_LOCATIONS(state) {
    state.student.selectedLocations.splice(0);
  },

  DEL_LOCATIONS(state, startIndex) {
    state.student.selectedLocations.splice(startIndex);
  },

  RESET_STUDENT(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state: studentState,
  mutations,
};
