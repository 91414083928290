import Vue from 'vue';
import axios from 'axios';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.use(VueGtag, {
  config: {
    id: 'G-LCK88T7YE7',
    params: {
      anonymize_ip: true,
    },
  },
}, router);

Vue.config.productionTip = false;

Vue.filter('formatDate', (date) => {
  if (!date) return '';
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
});

Vue.prototype.$http = axios;

axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
