import Vue from 'vue';
import Vuex from 'vuex';
import * as msal from '@azure/msal-browser';
import axios from 'axios';
import instances from './modules/instances';
import student from './modules/student';

Vue.use(Vuex);

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    authority: process.env.VUE_APP_MSAL_AUTHORITY,
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export default new Vuex.Store({
  state: {
    user: {},
    notification: {
      isVisible: false,
      text: '',
      color: '',
    },
    countries: [
      { country_name: 'Afghanistan', nationality: 'Afghane', code: 'AF' },
      { country_name: 'Afrique du Sud', nationality: 'Sud-Africaine', code: 'ZA' },
      { country_name: 'Åland', nationality: 'Ålandaise', code: 'AX' },
      { country_name: 'Albanie', nationality: 'Albanaise', code: 'AL' },
      { country_name: 'Algérie', nationality: 'Algérienne', code: 'DZ' },
      { country_name: 'Allemagne', nationality: 'Allemande', code: 'DE' },
      { country_name: 'Andorre', nationality: 'Andorrane', code: 'AD' },
      { country_name: 'Angola', nationality: 'Angolaise', code: 'AO' },
      { country_name: 'Antigua-et-Barbuda', nationality: 'Antiguaise-et-Barbudienne', code: 'AG' },
      { country_name: 'Arabie Saoudite', nationality: 'Saoudienne', code: 'SA' },
      { country_name: 'Argentine', nationality: 'Argentine', code: 'AR' },
      { country_name: 'Arménie', nationality: 'Armenienne', code: 'AM' },
      { country_name: 'Aruba', nationality: 'Arubaise', code: 'AW' },
      { country_name: 'Australie', nationality: 'Australienne', code: 'AU' },
      { country_name: 'Autriche', nationality: 'Autrichienne', code: 'AT' },
      { country_name: 'Azerbaïdjan', nationality: 'Azerbaïdjanaise', code: 'AZ' },
      { country_name: 'Bahamas', nationality: 'Bahamienne', code: 'BS' },
      { country_name: 'Bahreïn', nationality: 'Bahreinienne', code: 'BH' },
      { country_name: 'Bangladesh', nationality: 'Bangladaise', code: 'BD' },
      { country_name: 'Barbade', nationality: 'Barbadienne', code: 'BB' },
      { country_name: 'Belgique', nationality: 'Belge', code: 'BE' },
      { country_name: 'Belize', nationality: 'Belizienne', code: 'BZ' },
      { country_name: 'Bénin', nationality: 'Béninoise', code: 'BJ' },
      { country_name: 'Bhoutan', nationality: 'Bhoutanaise', code: 'BT' },
      { country_name: 'Biélorussie', nationality: 'Biélorusse', code: 'BY' },
      { country_name: 'Birmanie', nationality: 'Birmane', code: 'MM' },
      { country_name: 'Bolivie', nationality: 'Bolivienne', code: 'BO' },
      { country_name: 'Bosnie-Herzégovine', nationality: 'Bosnienne', code: 'BA' },
      { country_name: 'Botswana', nationality: 'Botswanaise', code: 'BW' },
      { country_name: 'Brésil', nationality: 'Brésilienne', code: 'BR' },
      { country_name: 'Brunéi', nationality: 'Brunéienne', code: 'BN' },
      { country_name: 'Bulgarie', nationality: 'Bulgare', code: 'BG' },
      { country_name: 'Burkina Faso', nationality: 'Burkinabée', code: 'BF' },
      { country_name: 'Burundi', nationality: 'Burundaise', code: 'BI' },
      { country_name: 'Cambodge', nationality: 'Cambodgienne', code: 'KH' },
      { country_name: 'Cameroun', nationality: 'Camerounaise ', code: 'CM' },
      { country_name: 'Canada', nationality: 'Canadienne', code: 'CA' },
      { country_name: 'Cap-Vert', nationality: 'Cap-Verdienne', code: 'CV' },
      { country_name: 'Chili', nationality: 'Chilienne', code: 'CL' },
      { country_name: 'Chine', nationality: 'Chinoise', code: 'CN' },
      { country_name: 'Chypre', nationality: 'Chypriote', code: 'CY' },
      { country_name: 'Colombie', nationality: 'Colombienne', code: 'CO' },
      { country_name: 'Comores', nationality: 'Comorienne', code: 'KM' },
      { country_name: 'Corée du Nord', nationality: 'Nord-coréenne', code: 'KP' },
      { country_name: 'Corée du Sud', nationality: 'Sud-Coréenne', code: 'KR' },
      { country_name: 'Costa rica', nationality: 'Costaricaine', code: 'CR' },
      { country_name: 'Côte d\'Ivoire', nationality: 'Ivoirienne', code: 'CI' },
      { country_name: 'Croatie', nationality: 'Croate', code: 'HR' },
      { country_name: 'Cuba', nationality: 'Cubaine', code: 'CU' },
      { country_name: 'Danemark', nationality: 'Danoise', code: 'DK' },
      { country_name: 'Djibouti', nationality: 'Djiboutienne', code: 'DJ' },
      { country_name: 'Dominique', nationality: 'Dominiquaise', code: 'DM' },
      { country_name: 'Égypte', nationality: 'Égyptienne', code: 'EG' },
      { country_name: 'Émirats Arabes Unis', nationality: 'Émirienne', code: 'AE' },
      { country_name: 'Équateur', nationality: 'Équatorienne', code: 'EC' },
      { country_name: 'Érythrée', nationality: 'Érythréenne', code: 'ER' },
      { country_name: 'Espagne', nationality: 'Espagnole', code: 'ES' },
      { country_name: 'Estonie', nationality: 'Estonienne', code: 'EE' },
      { country_name: 'États-Unis', nationality: 'Americaine', code: 'US' },
      { country_name: 'Éthiopie', nationality: 'Éthiopienne', code: 'ET' },
      { country_name: 'Fidji', nationality: 'Fidjienne', code: 'FJ' },
      { country_name: 'Finlande', nationality: 'Finlandaise', code: 'FI' },
      { country_name: 'France', nationality: 'Française', code: 'FR' },
      { country_name: 'Gabon', nationality: 'Gabonaise', code: 'GA' },
      { country_name: 'Gambie', nationality: 'Gambienne', code: 'GM' },
      { country_name: 'Géorgie', nationality: 'Georgienne', code: 'GE' },
      { country_name: 'Ghana', nationality: 'Ghanéenne', code: 'GH' },
      { country_name: 'Gibraltar', nationality: 'Gibraltariens', code: 'GI' },
      { country_name: 'Grèce', nationality: 'Hellénique', code: 'GR' },
      { country_name: 'Grenade', nationality: 'Grenadienne', code: 'GD' },
      { country_name: 'Groenland', nationality: 'Groenlandaise', code: 'GL' },
      { country_name: 'Guadeloupe', nationality: 'Guadeloupéen', code: 'GP' },
      { country_name: 'Guatemala', nationality: 'Guatémaltèque', code: 'GT' },
      { country_name: 'Guinée', nationality: 'Guinéenne', code: 'GN' },
      { country_name: 'Guinée-Bissau', nationality: 'Bissau-Guinéenne', code: 'GW' },
      { country_name: 'Guinée Équatoriale', nationality: 'Équato-Guineenne', code: 'GQ' },
      { country_name: 'Guyana', nationality: 'Guyanienne', code: 'GY' },
      { country_name: 'Guyane Française', nationality: 'Guyanaise', code: 'GF' },
      { country_name: 'Haïti', nationality: 'Haïtienne', code: 'HT' },
      { country_name: 'Honduras', nationality: 'Hondurienne', code: 'HN' },
      { country_name: 'Hong-Kong', nationality: 'Hongkongaise', code: 'HK' },
      { country_name: 'Hongrie', nationality: 'Hongroise', code: 'HU' },
      { country_name: 'Île Norfolk', nationality: 'Norfolkaise', code: 'NF' },
      { country_name: 'Îles Caïmans', nationality: 'Caïmanienne', code: 'KY' },
      { country_name: 'Îles Cook', nationality: 'Cookienne', code: 'CK' },
      { country_name: 'Îles Féroé', nationality: 'Féroïen', code: 'FO' },
      { country_name: 'Îles Malouines', nationality: 'Malouine', code: 'FK' },
      { country_name: 'Îles Marshall', nationality: 'Marshallaise', code: 'MH' },
      { country_name: 'Îles Salomon', nationality: 'Salomonaise', code: 'SB' },
      { country_name: 'Inde', nationality: 'Indienne', code: 'IN' },
      { country_name: 'Indonésie', nationality: 'Indonésienne', code: 'ID' },
      { country_name: 'Iran', nationality: 'Iranienne', code: 'IR' },
      { country_name: 'Iraq', nationality: 'Irakienne', code: 'IQ' },
      { country_name: 'Irlande', nationality: 'Irlandaise', code: 'IE' },
      { country_name: 'Islande', nationality: 'Islandaise', code: 'IS' },
      { country_name: 'Israël', nationality: 'Israélienne', code: 'IL' },
      { country_name: 'Italie', nationality: 'Italienne', code: 'IT' },
      { country_name: 'Jamaïque', nationality: 'Jamaïcaine', code: 'JM' },
      { country_name: 'Japon', nationality: 'Japonaise', code: 'JP' },
      { country_name: 'Jordanie', nationality: 'Jordanienne', code: 'JO' },
      { country_name: 'Kazakhstan', nationality: 'Kazakhstanaise', code: 'KZ' },
      { country_name: 'Kenya', nationality: 'Kenyane', code: 'KE' },
      { country_name: 'Kirghizistan', nationality: 'Kirghize', code: 'KG' },
      { country_name: 'Kiribati', nationality: 'Kiribatienne', code: 'KI' },
      { country_name: 'Koweït', nationality: 'Koweïtienne', code: 'KW' },
      { country_name: 'Laos', nationality: 'Laotienne', code: 'LA' },
      { country_name: 'La Réunion', nationality: 'Réunionnaise', code: 'RE' },
      { country_name: 'Lesotho', nationality: 'Lesothane', code: 'LS' },
      { country_name: 'Lettonie', nationality: 'Lettone', code: 'LV' },
      { country_name: 'Liban', nationality: 'Libanaise', code: 'LB' },
      { country_name: 'Libéria', nationality: 'Libérienne', code: 'LR' },
      { country_name: 'Libye', nationality: 'Libyenne', code: 'LY' },
      { country_name: 'Liechtenstein', nationality: 'Liechtensteinoise', code: 'LI' },
      { country_name: 'Lituanie', nationality: 'Lituanienne', code: 'LT' },
      { country_name: 'Luxembourg', nationality: 'Luxembourgeoise', code: 'LU' },
      { country_name: 'Macao', nationality: 'Macanaise', code: 'MO' },
      { country_name: 'Macédoine', nationality: 'Macédonienne', code: 'MK' },
      { country_name: 'Madagascar', nationality: 'Malgache', code: 'MG' },
      { country_name: 'Malaisie', nationality: 'Malaisienne', code: 'MY' },
      { country_name: 'Malawi', nationality: 'Malawienne', code: 'MW' },
      { country_name: 'Maldives', nationality: 'Maldivienne', code: 'MV' },
      { country_name: 'Mali', nationality: 'Maliennes', code: 'ML' },
      { country_name: 'Malte', nationality: 'Maltaise', code: 'MT' },
      { country_name: 'Maroc', nationality: 'Marocaine', code: 'MA' },
      { country_name: 'Martinique', nationality: 'Martiniquaise', code: 'MQ' },
      { country_name: 'Maurice', nationality: 'Mauricienne', code: 'MU' },
      { country_name: 'Mauritanie', nationality: 'Mauritanienne', code: 'MR' },
      { country_name: 'Mayotte', nationality: 'Mahoraise', code: 'YT' },
      { country_name: 'Mexique', nationality: 'Mexicaine', code: 'MX' },
      { country_name: 'Micronésie', nationality: 'Micronésienne', code: 'FM' },
      { country_name: 'Moldavie', nationality: 'Moldave', code: 'MD' },
      { country_name: 'Monaco', nationality: 'Monegasque', code: 'MC' },
      { country_name: 'Mongolie', nationality: 'Mongole', code: 'MN' },
      { country_name: 'Monténégro', nationality: 'Monténégrine', code: 'ME' },
      { country_name: 'Mozambique', nationality: 'Mozambicaine', code: 'MZ' },
      { country_name: 'Namibie', nationality: 'Namibienne', code: 'NA' },
      { country_name: 'Nauru', nationality: 'Nauruane', code: 'NR' },
      { country_name: 'Népal', nationality: 'Népalaise', code: 'NP' },
      { country_name: 'Nicaragua', nationality: 'Nicaraguayenne', code: 'NI' },
      { country_name: 'Niger', nationality: 'Nigérienne', code: 'NE' },
      { country_name: 'Nigéria', nationality: 'Nigériane', code: 'NG' },
      { country_name: 'Niué', nationality: 'Niuéenne', code: 'NU' },
      { country_name: 'Norvège', nationality: 'Norvégienne', code: 'NO' },
      { country_name: 'Nouvelle-Calédonie', nationality: 'Néo-Calédonienne', code: 'NC' },
      { country_name: 'Nouvelle-Zélande', nationality: 'Néo-Zélandaise', code: 'NZ' },
      { country_name: 'Oman', nationality: 'Omanaise', code: 'OM' },
      { country_name: 'Ouganda', nationality: 'Ougandaise', code: 'UG' },
      { country_name: 'Ouzbékistan', nationality: 'Ouzbéke', code: 'UZ' },
      { country_name: 'Pakistan', nationality: 'Pakistanaise', code: 'PK' },
      { country_name: 'Palaos', nationality: 'Palaosienne', code: 'PW' },
      { country_name: 'Palestine', nationality: 'Palestinienne', code: 'PS' },
      { country_name: 'Panama', nationality: 'Panaméenne', code: 'PA' },
      { country_name: 'Papouasie-Nouvelle-Guinée', nationality: 'Papouane-Néo-Guinéenne', code: 'PG' },
      { country_name: 'Paraguay', nationality: 'Paraguayenne', code: 'PY' },
      { country_name: 'Pays-Bas', nationality: 'Néerlandaise', code: 'NL' },
      { country_name: 'Pérou', nationality: 'Péruvienne', code: 'PE' },
      { country_name: 'Philippines', nationality: 'Philippine', code: 'PH' },
      { country_name: 'Pologne', nationality: 'Polonaise', code: 'PL' },
      { country_name: 'Polynésie Française', nationality: 'Polynésien', code: 'PF' },
      { country_name: 'Porto Rico', nationality: 'Portoricain', code: 'PR' },
      { country_name: 'Portugal', nationality: 'Portugaise', code: 'PT' },
      { country_name: 'Qatar', nationality: 'Qatarienne', code: 'QA' },
      { country_name: 'République Centrafricaine', nationality: 'Centrafricaine', code: 'CF' },
      { country_name: 'République Démocratique du Congo', nationality: 'Congolaise', code: 'CD' },
      { country_name: 'République Dominicaine', nationality: 'Dominicaine', code: 'DO' },
      { country_name: 'République du Congo', nationality: 'Congolaise', code: 'CG' },
      { country_name: 'République Tchèque', nationality: 'Tchèque', code: 'CZ' },
      { country_name: 'Roumanie', nationality: 'Roumaine', code: 'RO' },
      { country_name: 'Royaume-Uni', nationality: 'Britannique', code: 'GB' },
      { country_name: 'Russie', nationality: 'Russe', code: 'RU' },
      { country_name: 'Rwanda', nationality: 'Rwandaise', code: 'RW' },
      { country_name: 'Saint-Christophe-et-Niévès', nationality: 'Kittitienne et Névicienne', code: 'KN' },
      { country_name: 'Sainte-Lucie', nationality: 'Saint-Lucienne', code: 'LC' },
      { country_name: 'Saint-Marin', nationality: 'Saint-Marinaise', code: 'SM' },
      { country_name: 'Saint-Pierre-et-Miquelon', nationality: 'Miquelonaisz', code: 'PM' },
      { country_name: 'Saint-Vincent-et-les Grenadines', nationality: 'Saint-Vincentaise et Grenadine', code: 'VC' },
      { country_name: 'Salvador', nationality: 'Salvadorienne', code: 'SV' },
      { country_name: 'Samoa', nationality: 'Samoane', code: 'WS' },
      { country_name: 'Sao Tomé-et-Principe', nationality: 'Santoméenne', code: 'ST' },
      { country_name: 'Sénégal', nationality: 'Sénégalaise', code: 'SN' },
      { country_name: 'Serbie', nationality: 'Serbe', code: 'RS' },
      { country_name: 'Seychelles', nationality: 'Seychelloise', code: 'SC' },
      { country_name: 'Sierra Leone', nationality: 'Sierra-Léonaise', code: 'SL' },
      { country_name: 'Singapour', nationality: 'Singapourienne', code: 'SG' },
      { country_name: 'Slovaquie', nationality: 'Slovaque', code: 'SK' },
      { country_name: 'Slovénie', nationality: 'Slovène', code: 'SI' },
      { country_name: 'Somalie', nationality: 'Somalienne', code: 'SO' },
      { country_name: 'Soudan', nationality: 'Soudanaise', code: 'SD' },
      { country_name: 'Soudan du Sud', nationality: 'Sud-Soudanaise', code: 'SS' },
      { country_name: 'Sri Lanka', nationality: 'Sri-Lankaise', code: 'LK' },
      { country_name: 'Suède', nationality: 'Suédoise', code: 'SE' },
      { country_name: 'Suisse', nationality: 'Suisse', code: 'CH' },
      { country_name: 'Suriname', nationality: 'Surinamaise', code: 'SR' },
      { country_name: 'Swaziland', nationality: 'Swazie', code: 'SZ' },
      { country_name: 'Syrie', nationality: 'Syrienne', code: 'SY' },
      { country_name: 'Tadjikistan', nationality: 'Tadjike', code: 'TJ' },
      { country_name: 'Taïwan', nationality: 'Taïwanaise', code: 'TW' },
      { country_name: 'Tanzanie', nationality: 'Tanzanienne', code: 'TZ' },
      { country_name: 'Tchad', nationality: 'Tchadienne', code: 'TD' },
      { country_name: 'Thaïlande', nationality: 'Thaïlandaise', code: 'TH' },
      { country_name: 'Timor-Leste', nationality: 'Est-Timoraise', code: 'TL' },
      { country_name: 'Togo', nationality: 'Togolaise', code: 'TG' },
      { country_name: 'Tonga', nationality: 'Tonguienne', code: 'TO' },
      { country_name: 'Trinité-et-Tobago', nationality: 'Trinidadienne', code: 'TT' },
      { country_name: 'Tunisie', nationality: 'Tunisienne', code: 'TN' },
      { country_name: 'Turkménistan', nationality: 'Turkmène', code: 'TM' },
      { country_name: 'Turquie', nationality: 'Turque', code: 'TR' },
      { country_name: 'Tuvalu', nationality: 'Tuvaluane', code: 'TV' },
      { country_name: 'Ukraine', nationality: 'Ukrainienn', code: 'UA' },
      { country_name: 'Uruguay', nationality: 'Uruguayenne', code: 'UY' },
      { country_name: 'Vanuatu', nationality: 'Vanuatuane', code: 'VU' },
      { country_name: 'Venezuela', nationality: 'Vénézuélienne', code: 'VE' },
      { country_name: 'Viet Nam', nationality: 'Vietnamienne', code: 'VN' },
      { country_name: 'Yémen', nationality: 'Yéménite', code: 'YE' },
      { country_name: 'Zambie', nationality: 'Zambienne', code: 'ZM' },
      { country_name: 'Zimbabwe', nationality: 'Zimbabwéenne', code: 'ZW' },
    ],
    request: {
      scopes: [],
      account: null,
    },
  },

  getters: {
    isAuth: (state) => !(state.user
    && Object.keys(state.user).length === 0
    && state.user.constructor === Object),
    hasAccessRole: (state) => (state.user.roles.includes('APP.INS.ACCESS')),
    hasAdminRole: (state) => (state.user.roles.includes('APP.INS.ADMIN')),
    hasDahuRole: (state) => (state.user.roles.some((role) => role.includes('APP.INS.DAHU.'))),
    hasEnfRole: (state) => (state.user.roles.some((role) => role.includes('APP.INS.ENF.'))),
    hasEnrRole: (state) => (state.user.roles.some((role) => role.includes('APP.INS.ENR.'))),
    hasEnfvRole: (state) => (state.user.roles.some((role) => role.includes('APP.INS.ENFV.'))),
    hasExtRole: (state) => (state.user.roles.some((role) => role.includes('APP.INS.EXT'))),
    hasParaRole: (state) => (state.user.roles.some((role) => role.includes('APP.INS.PARA.'))),
  },

  mutations: {
    SET_NOTIFICATION(state, data) {
      state.notification = data;
    },

    SET_COUNTRIES(state, data) {
      state.countries = data;
    },

    SET_USER(state, user) {
      state.user = user;
    },

    SET_REQUEST_ACCOUNT(state, req) {
      state.request.account = req.account;
      state.request.scopes = req.scopes;
    },
  },
  actions: {
    async checkAuth({ commit }) {
      try {
        let account = '';
        const tokenResponse = await msalInstance.handleRedirectPromise();
        if (tokenResponse !== null) {
          // coming back from a successful authentication redirect
          account = tokenResponse.account;
        } else {
          // not coming back from an auth redirect
          const currentAccounts = msalInstance.getAllAccounts();
          if (currentAccounts.length > 1) {
            account = currentAccounts.find(
              (elem) => elem.username.includes('@condorcet.be'),
            );
          } else if (currentAccounts.length === 1) {
            account = currentAccounts[0];
          }
        }
        if (account) {
          commit('SET_USER', {
            roles: account.idTokenClaims.roles,
            name: account.name,
            username: account.username,
          });
        }
      } catch (error) {
        // handle error, either in the library or coming back from the server
        console.log('error in checkAuth catch block');
        console.log(error);
      }
    },

    login({ state }) {
      msalInstance.loginRedirect(state.request);
    },

    logout() {
      // const logoutRequest = {
      //   account: myMsal.getAccountByUsername(state.user.username),
      // };
      msalInstance.logout({});
    },

    async getAccessToken({ dispatch, commit, state }, needsGraph = false) {
      let scopes = [];
      let account = null;
      if (needsGraph) {
        scopes = [
          'Directory.Read.All',
          'GroupMember.ReadWrite.All',
        ];
      } else {
        scopes = ['api://a7aa84aa-4106-433e-9beb-a03329115b06/access_as_user'];
      }
      account = msalInstance.getAccountByUsername(state.user.username);
      commit('SET_REQUEST_ACCOUNT', { scopes, account });
      let tokenResponse = '';
      try {
        tokenResponse = await msalInstance.acquireTokenSilent(state.request);
        axios.defaults.headers.common.Authorization = `Bearer ${tokenResponse.accessToken}`;
        // console.log(tokenResponse);
        return 'success';
      } catch (silentError) {
        console.log(silentError);
        if (dispatch('requiresInteraction', silentError)) {
          try {
            tokenResponse = await msalInstance.acquireTokenRedirect(state.request);
            axios.defaults.headers.common.Authorization = `Bearer ${tokenResponse.accessToken}`;
            return 'success';
          } catch (popupError) {
            console.log(popupError);
            return 'fail';
          }
        } else {
          return 'fail';
        }
      }
    },

    requiresInteraction(errorCode) {
      if (!errorCode || !errorCode.length) {
        return false;
      }
      return errorCode === 'consent_required'
        || errorCode === 'interaction_required'
        || errorCode === 'login_required';
    },
  },
  modules: {
    instances,
    student,
  },
});
