<template>
  <v-snackbar
    v-model="isVisible"
    :color="color"
  >
    {{ $store.state.notification.text }}

    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        @click.stop="isVisible = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'NotificationSnackbar',

  computed: {
    isVisible: {
      get() {
        return this.$store.state.notification.isVisible;
      },
      set(val) {
        this.$store.commit('SET_NOTIFICATION', { isVisible: val, text: '', color: '' });
      },
    },

    color() {
      return this.$store.state.notification.color;
    },
  },
};
</script>
