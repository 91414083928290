import axios from 'axios';

export default {
  namespaced: true,

  state: {
    instances: [],
    instancesAreLoading: false,
  },

  getters: {
  },

  mutations: {
    SET_LOADING(state, value) {
      state.instancesAreLoading = value;
    },

    SET_INSTANCES(state, instances) {
      state.instances = instances;
    },

    DELETE_INSTANCE(state, index) {
      state.instances.splice(index, 1);
    },

    UPSERT_INSTANCE(state, data) {
      if (data.index > -1) {
        Object.assign(state.instances[data.index], data.instance);
      } else {
        state.instances.push(data.instance);
      }
    },
  },

  actions: {
    async getInstances({ commit, dispatch }) {
      await dispatch('getAccessToken', null, { root: true });
      try {
        commit('SET_LOADING', true);
        const response = await axios.get('admin/instance-get.php');
        commit('SET_INSTANCES', response.data);
      } catch (error) {
        commit('SET_NOTIFICATION', {
          isVisible: true,
          text: 'Impossible de récupérer les instances',
          color: 'error',
        }, { root: true });
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },

  modules: {
  },
};
