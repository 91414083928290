<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-container class="d-flex align-center pa-0 px-md-3">
        <router-link to="/">
          <v-img
            :src="require('@/assets/thumbnail_Condorcet_neg.c33101cd.png')"
            :max-width="$vuetify.breakpoint.smAndDown ? 168.06 : 196.06"
            contain
          ></v-img>
        </router-link>

        <v-spacer></v-spacer>

        <account-menu></account-menu>
      </v-container>
    </v-app-bar>

    <v-main>
      <router-view></router-view>

      <notification-snackbar></notification-snackbar>
    </v-main>

    <v-footer
      app
      class="justify-center"
      color="secondary"
      dark
    >
      <div class="text-caption text-center">
        <!-- Contact support link -->
        <v-dialog
          v-model="dialHelpIsVisible"
          max-width="400"
        >
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="white--text"
            >
              J'ai besoin d'aide
            </a>
          </template>

          <v-card>
            <v-card-title class="primary white--text">
              Support

              <v-spacer></v-spacer>

              <v-btn
                icon
                dark
                @click="dialHelpIsVisible = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>

            </v-card-title>

            <v-card-text class="pt-5">
              <p>
                Une question relative à la pré-préinscription aux sections sensibles ?
                <br>
                Contactez
                <a href="mailto: preinscription@condorcet.be">
                  le service inscription
                </a>
              </p>

              <p>
                Une question relative à votre demande / inscription ?
                <br>
                Contactez
                <a href="mailto: service.juridique@condorcet.be">
                  le service juridique
                </a>
              </p>

              <p class="mb-0">
                Un problème technique ?
                <br>
                Contactez
                <a
                  href="https://support.ecampus-hainaut.be/contact/"
                  target="_blank"
                >
                  le support eCampus
                </a>
              </p>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Campus Numérique link -->
        <div>
          Développé par le
          <a
            href="https://www.ecampus-hainaut.be/"
            target="_blank"
            class="white--text"
          >
            Campus Numérique Hainaut - Enseignement
          </a>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import NotificationSnackbar from '@/components/notification/NotificationSnackbar.vue';
import AccountMenu from './components/notification/AccountMenu.vue';

export default {
  name: 'App',

  components: {
    'notification-snackbar': NotificationSnackbar,
    'account-menu': AccountMenu,
  },

  data: () => ({
    dialHelpIsVisible: false,
  }),
};
</script>
