import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },

  {
    path: '/error/unexpected',
    name: 'ErrorUnexpected',
    component: () => import('@/views/ErrorUnexpected'),
    beforeEnter: (to, from, next) => {
      if (!from.name) next({ name: 'Home' });
      else next();
    },
  },

  {
    path: '/enf',
    name: 'GuestEnf',
    component: () => import('@/views/GuestAll.vue'),
    alias: ['/enr', '/dahu'],
  },

  {
    path: '/enfv',
    name: 'EnfV',
    component: () => import('@/views/EnfV.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/pss',
    name: 'Para',
    component: () => import('@/views/Para.vue'),
  },

  {
    path: '/pss/demo',
    name: 'Para',
    component: () => import('@/views/ParaDemo.vue'),
  },

  {
    path: '/admin',
    redirect: '/admin/login',
  },

  {
    path: '/admin/management',
    name: 'AdminManagement',
    component: () => import('@/views/AdminManagement.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters.hasAccessRole && store.getters.hasAdminRole) {
        next();
      } else {
        next('/admin/instances');
      }
    },
  },

  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => import('@/views/AdminLogin.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuth && store.getters.hasAccessRole) {
        next('/admin/instances');
      } else if (store.getters.isAuth) {
        next('/enfv');
      } else {
        next();
      }
    },
  },

  {
    path: '/admin/instances',
    name: 'AdminInstances',
    component: () => import('@/views/AdminInstances.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters.hasAccessRole) {
        next();
      } else {
        next('/');
      }
    },
  },

  {
    path: '/admin/dahu/:instanceId',
    // name: 'AdminDahu',
    component: () => import('@/views/AdminDahu.vue'),
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'AdminDahuCursus',
        component: () => import('@/views/AdminDahuCursus.vue'),
      },

      {
        path: 'cursus/:cursusId',
        name: 'AdminDahuRegistrations',
        component: () => import('@/views/AdminDahuRegistrations.vue'),
        props: true,
      },
    ],
    beforeEnter: (to, from, next) => {
      // eslint-disable-next-line max-len
      if (store.getters.hasAccessRole && (store.getters.hasAdminRole || store.getters.hasDahuRole || store.getters.hasExtRole)) {
        next();
      } else {
        next('/admin/instances');
      }
    },
  },

  {
    path: '/admin/pss/:instanceId',
    component: () => import('@/views/AdminPara.vue'),
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'AdminParaCursus',
        component: () => import('@/views/AdminParaCursus.vue'),
      },

      {
        path: 'cursus/:cursusId',
        name: 'AdminParaRegistrations',
        component: () => import('@/views/AdminParaRegistrations.vue'),
        props: true,
      },

      {
        path: 'cursus/:cursusId/registration/:registrationId',
        name: 'AdminParaRegistrationDetails',
        component: () => import('@/views/AdminParaRegistrationDetails.vue'),
        props: true,
      },
    ],
    beforeEnter: (to, from, next) => {
      if (
        store.getters.hasAccessRole
        && (store.getters.hasAdminRole || store.getters.hasParaRole || store.getters.hasExtRole)
      ) {
        next();
      } else {
        next('/admin/instances');
      }
    },
  },

  {
    path: '/admin/enf/:instanceId',
    // name: 'AdminEnf',
    component: () => import('@/views/AdminEnf.vue'),
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'AdminEnfCursus',
        component: () => import('@/views/AdminEnfCursus.vue'),
      },

      {
        path: 'cursus/:cursusId',
        name: 'AdminEnfRegistrations',
        component: () => import('@/views/AdminEnfRegistrations.vue'),
        props: true,
      },

      {
        path: 'cursus/:cursusId/registration/:registrationId',
        name: 'AdminEnfRegistrationDetails',
        component: () => import('@/views/AdminEnfRegistrationDetails.vue'),
        props: true,
      },
    ],
    beforeEnter: (to, from, next) => {
      // eslint-disable-next-line max-len
      if (store.getters.hasAccessRole && (store.getters.hasAdminRole || store.getters.hasEnfRole || store.getters.hasExtRole)) {
        next();
      } else {
        next('/admin/instances');
      }
    },
  },

  {
    path: '/admin/enfv/:instanceId',
    // name: 'AdminEnf',
    component: () => import('@/views/AdminEnfV.vue'),
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'AdminEnfVCursus',
        component: () => import('@/views/AdminEnfVCursus.vue'),
      },

      {
        path: 'cursus/:cursusId',
        name: 'AdminEnfVRegistrations',
        component: () => import('@/views/AdminEnfVRegistrations.vue'),
        props: true,
      },

      {
        path: 'cursus/:cursusId/registration/:registrationId',
        name: 'AdminEnfVRegistrationDetails',
        component: () => import('@/views/AdminEnfVRegistrationDetails.vue'),
        props: true,
      },
    ],
    beforeEnter: (to, from, next) => {
      // eslint-disable-next-line max-len
      if (store.getters.hasAccessRole && (store.getters.hasAdminRole || store.getters.hasEnfvRole || store.getters.hasExtRole)) {
        next();
      } else {
        next('/admin/instances');
      }
    },
  },

  {
    path: '/admin/enr/:instanceId',
    // name: 'AdminEnr',
    component: () => import('@/views/AdminEnr.vue'),
    props: true,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'AdminEnrCursus',
        component: () => import('@/views/AdminEnrCursus.vue'),
      },

      {
        path: 'cursus/:cursusId',
        name: 'AdminEnrRegistrations',
        component: () => import('@/views/AdminEnrRegistrations.vue'),
        props: true,
      },
    ],
    beforeEnter: (to, from, next) => {
      // eslint-disable-next-line max-len
      if (store.getters.hasAccessRole && (store.getters.hasAdminRole || store.getters.hasEnrRole || store.getters.hasExtRole)) {
        next();
      } else {
        next('/admin/instances');
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters.isAuth) await store.dispatch('checkAuth');
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuth) next();
    else next('/admin/login');
  } else {
    next();
  }
});

export default router;
