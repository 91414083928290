<template>
  <v-menu
    offset-y
    bottom
    max-width="280px"
    v-if="isAuth"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        x-large
        v-on="on"
        class="mr-0"
      >
        <v-avatar
          size="48"
        >
          <v-img
            :src="require('@/assets/undraw_profile_pic_ic5t.svg')"
          ></v-img>
        </v-avatar>
      </v-btn>
    </template>

    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ user.username }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list
      nav
      dense
    >
      <v-list-item
        v-if="$store.getters.hasAdminRole"
        to="/admin/management"
      >
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Gestion</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="$store.getters.hasParaRole || $store.getters.hasAdminRole"
        to="/pss/demo"
      >
        <v-list-item-icon>
          <v-icon>mdi-book-open</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Demo PSS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout-variant</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Déconnexion</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'AccountMenu',

  computed: {
    ...mapState(['user']),
    ...mapGetters(['isAuth']),
  },

  methods: {
    ...mapActions(['logout']),
  },
};
</script>
