<template>
  <v-container class="my-12">
    <h1 class="text-h4">Bienvenue sur Condorcet - INS</h1>

    <div class="text-subtitle-1 my-6">
      Cette plateforme numérique vous permet d'introduire votre demande d'inscription
      en tant qu'étudiant hors union européenne, non résident ou non finançable.
    </div>

    <v-row>
      <v-col
        v-for="(card, index) in cards"
        :key="index"
        sm="6"
        xl="3"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            rounded="xl"
            :elevation="hover ? '6' : ''"
            :to="card.path"
          >
            <v-card-text>
              <v-img
                :src="require(`@/assets/${card.picture}.svg`)"
                height="200"
                contain
              ></v-img>
            </v-card-text>

            <v-card-title class="justify-center pt-0">{{ card.title }}</v-card-title>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',

  data: () => ({
    cards: [
      { path: '/dahu', title: 'Etudiant Hors UE', picture: 'undraw_adventure_4hum' },
      { path: '/enr', title: 'Etudiant Non Résident', picture: 'undraw_gdpr_3xfb' },
      { path: '/enf', title: 'Etudiant Non Finançable Externe', picture: 'undraw_Lost_re_xqjt' },
      { path: '/enfv', title: 'Etudiant Non Finançable Vétéran', picture: 'undraw_exams_g4ow' },
      { path: '/pss', title: 'Sections Sensibles', picture: 'undraw_medicine_b-1-ol' },
    ],
  }),
};
</script>
